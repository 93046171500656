import React from "react";
import Contact from "../Contact";

const HomePage = () => {
  return (
    <div className="homepage">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand glow" href="#">
            Spark
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor02">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link active" href="#">
                  Home
                  <span className="visually-hidden">(current)</span>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#features" className="nav-link">
                  Features
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a href="#pricing" className="nav-link">
                  Pricing
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </li> */}
              <div>
                <Contact />
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <br />
      <div className="container mx-auto">
        <div className="row align-items-center justify-content-center overlay">
          <div className="col-md-6 col-12 order-md-1">
            <div className="unique-container">
              <div className="stack" style={{ "--stacks": 3 }}>
                <span style={{ "--index": 0 }}>
                  Revolutionize Your Workflow with Spark
                </span>
                <span style={{ "--index": 1 }}>
                  Revolutionize Your Workflow with Spark
                </span>
                <span style={{ "--index": 2 }}>
                  Revolutionize Your Workflow with Spark
                </span>
              </div>
            </div>

            <h4 className="typed-center ">
              <span id="typed" />
              <span className="typed-center typed-cursor" />
            </h4>
            <div id="typed-strings" className="typed-center">
              <p>Automate</p>
              <p>Monitor</p>
              <p>Optimize</p>
              <p>Scale</p>
            </div>
            <div className="col-4 col-md-2 mx-auto order-md-2">
              <img
                src="robot_hand.png"
                alt="A robot hand"
                className="mx-auto img-fluid img-md"
              />
            </div>
            <h4>
              Our marketing automation tools will help you streamline your
              processes and boost productivity.{" "}
              <span className="robot">🤖</span>
            </h4>
            <div className="progress col">
              <div
                id="progress-bar1"
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: 0, transition: "width 2s" }}
                aria-valuenow={0}
                aria-valuemin={0}
                aria-valuemax={100}
              />
              <div
                id="progress-bar2"
                className="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                style={{ width: "50%", transition: "width 2s" }}
                aria-valuenow={30}
                aria-valuemin={0}
                aria-valuemax={100}
              />
              <div
                id="progress-bar3"
                className="progress-bar progress-bar-striped bg-info"
                role="progressbar"
                style={{ width: "50%", transition: "width 2s" }}
                aria-valuenow={20}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Save time by automating the creation of ad rules
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      With Spark, you don't have to spend hours manually
                      creating ad rules for each and every one of your products.
                      Simply tell Spark what your goals are and it will generate
                      custom ad rules for you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Highly flexible and customizable
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Spark is designed to be highly flexible and customizable,
                      so you can create an ad rules template once and use it on
                      thousands of your products.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Monitors your ads anywhere and anytime
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      With Spark, you can monitor your ads from anywhere and
                      anytime, giving you complete visibility and control over
                      your ad campaigns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Data driven recommendations
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Unlock the power of data-driven insights with Spark LLM
                      models. Our meticulously trained algorithms analyze a vast
                      array of advertisements, equipping you with tailored
                      recommendations that enable optimal campaign optimization.
                      Experience the revolutionary potential of data-driven
                      decision-making and witness your advertising strategies
                      reach new heights of effectiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Gain an unfair advertising advantage
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Unlock the power of million-dollar copywriting skills,
                      right at your fingertips. In the realm of marketing,
                      connecting with the hearts and souls of your customers is
                      paramount. Crafting compelling messages that resonate with
                      your target audience requires extensive research and
                      meticulous effort. However, with Spark, we've already done
                      all the groundwork for you. Stay one step ahead of your
                      competition with a cutting-edge advantage, effortlessly
                      testing and refining effective ad copies on autopilot.
                      Streamline your advertising process and witness remarkable
                      results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <canvas id="matrixCanvas" />
            <div id="particles-js" />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default HomePage;
