import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    const handleClick = () => {
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(["openForm", "SJMiZh"]);
    };

    const navLink = document.getElementById("contact-link");
    navLink.addEventListener("click", handleClick);

    return () => {
      navLink.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div>
      <li className="nav-item">
        <a id="contact-link" href="#" className="nav-link">
          Contact
        </a>
      </li>
    </div>
  );
};

export default Contact;
